#batch-modal {
  .button-expand-msg-container {
    font-size: 1.5rem;
  }

  .button-expand-msg {
    all: unset;
    color: black;
    :hover {
      cursor: pointer;
    }
  }
}
