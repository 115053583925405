#dashboard-wrapper {
  .no-issues {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .with-issues {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .admin-button {
    margin-right: 0.5rem;
    vertical-align: text-bottom;
    cursor: pointer;
  }

  .with-issues .admin-button:hover {
    color: red;
  }
  .no-issues .admin-button:hover {
    color: green;
  }

  .version-indicator {
    vertical-align: text-bottom;
  }

  .warning-triangle {
    vertical-align: text-bottom;
  }

  .button-nostyle {
    all: unset;
    :hover {
      cursor: pointer;
      color: orange;
    }
  }
}
