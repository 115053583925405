#script-wrapper {
  .scrollable-column {
    height: 100%;
    overflow-y: auto;
    background-color: white;
  }

  .row-content {
    height: 90%;
  }

  .icons-container {
    margin-left: 2rem;
  }

  .icon {
    font-size: 1.25rem;
    margin-right: 1rem;
    vertical-align: middle;
  }

  .search-field {
    margin-right: 1rem;
  }

  .search-btn {
    margin-right: 0.5rem;
  }

  .top-bar {
    background-color: white;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}
