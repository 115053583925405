@import 'variables';

#root {
  background-color: $bg-color-main;
  min-height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

p {
  color: black;
}

.r {
  text-align: right;
}

.c {
  text-align: center;
}

.fs-9 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.bg-internal {
  background-color: $bg-color-internal !important;
}

.bg-external {
  background-color: $bg-color-external !important;
}
