#home-wrapper {
  .scrollable-column {
    height: 90%;
    overflow-y: auto;
  }

  .top-bar {
    background-color: white;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .search-field {
    margin-right: 1rem;
  }
}
